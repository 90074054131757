import * as fk from "@flixcheck/flixkit";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { ShopReportData } from "./ShopReportData";
import { getReport } from "../../services/backend.service";
import { TelekomButton } from "../../shared/style/Button";
import { MagentaColor } from "../../shared/style/Colors";
import { ReportData, ReportDateSettings } from "../../interfaces/ShopReport";

export function ShopReportCreate(): JSX.Element {
    const { addNotification } = fk.useNotifications();

    const reportDataRef = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [report, setReport] = useState<ReportData>();

    const [dateSettings, setDateSettings] = useState<ReportDateSettings>({
        from: new Date(),
        to: new Date()
    });

    const onValidationRequest = useCallback(
        (data: ReportDateSettings) => {
            const errors: fk.FormFieldError<ReportDateSettings>[] = [];

            if (!data.from) {
                errors.push({
                    target: "from",
                    message: "Dieses Feld muss ausgefüllt werden."
                });
            }

            if (!data.to) {
                errors.push({
                    target: "to",
                    message: "Dieses Feld muss ausgefüllt werden."
                });
            }

            return errors;
        },
        []
    );

    const form = fk.useForm<ReportDateSettings>(
        useMemo<fk.FormOptions<ReportDateSettings>>(() => {
            return {
                data: dateSettings,
                errorsVisible: true,
                onChange: (newData) => {
                    setDateSettings(newData);
                },
                onValidationRequest,
                onValidChange: setIsValid
            };
        }, [onValidationRequest, dateSettings])
    );

    const fetchReport = useCallback(async () => {
        try {
            if (form.data.from && form.data.to) {
                setLoading(true);
                setIsValid(false);
                const response = await getReport(
                    form.data.from.toISOString(),
                    form.data.to.toISOString()
                );

                if (response){
                    setLoading(false);
                    setIsValid(true);
                }

                setReport(response?.data);
                fk.scrollTo(reportDataRef);
            }
        } catch (error) {
            addNotification({
                type: "error",
                message: "Es ist ein Fehler beim Laden des Reports aufgetreten",
                duration: fk.DefaultNotificationDurations.Medium
            });
            setLoading(false);
            setIsValid(true);
        }
    }, [addNotification, form.data.from, form.data.to]);

    const onCreateReport = useCallback(() => {

        void fetchReport();
    }, [fetchReport]);

    const fieldHandlers = useMemo(
        () => ({
            from: fk.createFormDatePickerHandlers(form, "from"),
            to: fk.createFormDatePickerHandlers(form, "to")
        }),
        [form]
    );

    const reportFromDatePicker = useMemo(
        () => <fk.DatePicker id="reportFrom" value={form.data.from} {...fieldHandlers.from} />,

        [fieldHandlers.from, form.data.from]
    );

    const reportToDatePicker = useMemo(
        () => <fk.DatePicker id="reportUntil" value={form.data.to} {...fieldHandlers.to} />,
        [form.data.to, fieldHandlers.to]
    );

    const fromErrorList = useMemo(() => form.getErrors.call(undefined, "from"), [form.getErrors]);

    const toErrorList = useMemo(() => form.getErrors.call(undefined, "to"), [form.getErrors]);

    const isSubmitDisabled = useMemo(() => !isValid, [isValid]);

    return (
        <fk.Container>
            <fk.Box padding="xlarge">
                <fk.Stack orientation="vertical" spacingFactor="xlarge">
                    <fk.Heading level={2} inline={false}>
                        Performance aller Partnershops
                    </fk.Heading>
                    <fk.Row crossAxisRule="reversed">
                        <fk.Column md={7}>
                            <fk.Stack
                                spacingFactor="medium"
                                orientation="horizontal"
                                alongAxisRule="expanded"
                                expansion="alongAxis"
                            >
                                <fk.FormGroup>
                                    <fk.FormLabel for="reportFrom" text="Von" />
                                    <fk.Stack
                                        orientation="horizontal"
                                        expansion="alongAxis"
                                        alongAxisRule="expanded"
                                    >
                                        {reportFromDatePicker}
                                    </fk.Stack>
                                    <fk.FormErrorList errors={fromErrorList} />
                                </fk.FormGroup>
                                <fk.FormGroup>
                                    <fk.FormLabel for="reportUntil" text="Bis" />
                                    <fk.Stack
                                        orientation="horizontal"
                                        expansion="alongAxis"
                                        alongAxisRule="expanded"
                                    >
                                        {reportToDatePicker}
                                    </fk.Stack>
                                    <fk.FormErrorList errors={toErrorList} />
                                </fk.FormGroup>
                            </fk.Stack>
                        </fk.Column>
                        <fk.Column xs={12} md={4}>
                            <fk.Row alongAxisRule="reversed">
                                <fk.Column lg={4} md={5}>
                                    <fk.Stack
                                        orientation="vertical"
                                        alongAxisRule="reversed"
                                        expansion="alongAxis"
                                    >
                                        <TelekomButton
                                            color={MagentaColor}
                                            label="Anfragen"
                                            type="submit"
                                            disabled={isSubmitDisabled}
                                            onClick={onCreateReport}
                                            overwriteWithLoadingSpinner={isLoading}
                                        />
                                    </fk.Stack>
                                </fk.Column>
                            </fk.Row>
                        </fk.Column>
                    </fk.Row>
                    {report ? (
                        <fk.StackItem>
                            <fk.Row>
                                <fk.Column xs={12}>
                                    <fk.RefContainer ref={reportDataRef}>
                                        <ShopReportData settings={dateSettings} report={report} />
                                    </fk.RefContainer>
                                </fk.Column>
                            </fk.Row>
                        </fk.StackItem>
                    ) : null}
                </fk.Stack>
            </fk.Box>
        </fk.Container>
    );
}
