import * as fk from "@flixcheck/flixkit";
import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { ReportPartner } from "../../interfaces/ShopReport";
import { ReportTableProps } from "../../interfaces/ShopReportTableProps";

const ReportTableWrapper = styled.div`
    @media print {
        page-break-after: always;
    }
`;

export function ShopReportTable(props: ReportTableProps): JSX.Element {
    const { data, dates } = props;
    const { formatDate } = fk.useDate();

    const datesReport = useMemo(() => {
        if (!dates.to || !dates.from) {
            return {};
        }

        return {
            from: formatDate(dates.from, { format: fk.LocalizedFormat.DateTime }) ?? "",
            to: formatDate(dates.to, { format: fk.LocalizedFormat.DateTime }) ?? "",
            created: dates.created
                ? formatDate(dates.created, { format: fk.LocalizedFormat.DateTime })
                : null
        };
    }, [dates.to, dates.from, dates.created, formatDate]);

    const partnerData  = useMemo(() => {
        return Object.keys(data)
            .filter((key) => key !== "sums" && key !== "from" && key !== "to")
            .map((partnerNr) => {return {partnerNr, data: data[partnerNr]}});
            }, [data])

    const tableRef = useRef<HTMLDivElement>(null);

    const { paginatedData, paginationButtons} =
        fk.usePagination<{partnerNr: string, data: ReportPartner}>({
            dataToPaginate: partnerData,
            scrollToElement: tableRef
        });

    const reportTableRows = useMemo(() => {
        return paginatedData
            .map((partner) => {
                const partnerNr = partner.partnerNr;

                if (partner.data &&
                    typeof partner.data === "object" &&
                    "created" in partner.data &&
                    "answered" in partner.data &&
                    typeof partner.data.created === "object" &&
                    typeof partner.data.answered === "object") {
                    const partnerData: ReportPartner = partner.data;

                    return (
                        <fk.TableRow key={partnerNr}>
                            <fk.TableColumn>{partnerNr}</fk.TableColumn>
                            <fk.TableColumn>
                                {partnerData.created.fn + partnerData.created.mf}
                            </fk.TableColumn>
                            <fk.TableColumn>{partnerData.created.mf}</fk.TableColumn>
                            <fk.TableColumn>{partnerData.created.fn}</fk.TableColumn>
                            <fk.TableColumn>
                                {partnerData.answered.fn + partnerData.answered.mf}
                            </fk.TableColumn>
                            <fk.TableColumn>{partnerData.answered.mf}</fk.TableColumn>
                            <fk.TableColumn>{partnerData.answered.fn}</fk.TableColumn>
                        </fk.TableRow>
                    );
                }

                return null;
            })
            .filter(Boolean);
    }, [paginatedData]);


    const reportTableSums = useMemo(() => {
        if (data.sums) {
            return (
                <fk.TableRow key="reportTableSums">
                    <fk.TableColumn>{"Summe"}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.created.fn + data.sums.created.mf}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.created.mf}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.created.fn}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.answered.fn + data.sums.answered.mf}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.answered.mf}</fk.TableColumn>
                    <fk.TableColumn>{data.sums.answered.fn}</fk.TableColumn>
                </fk.TableRow>
            );
        }
        return null;
    }, [data.sums]);

    return (
        <ReportTableWrapper ref={tableRef}>
            <fk.Stack orientation="vertical" spacingFactor="medium">
                <fk.Stack orientation="horizontal" spacingFactor="medium" crossAxisRule="center">
                    <fk.Paragraph>{`${datesReport.from} - ${datesReport.to} `}</fk.Paragraph>

                    {!!dates.created && <fk.Hint>{`Erstellt am: ${datesReport.created}`}</fk.Hint>}
                </fk.Stack>
                <fk.StackItem>
                    <fk.Table>
                        <fk.TableHead>
                            <fk.TableRow>
                                <fk.TableHeading>{"Partner Nr."}</fk.TableHeading>
                                <fk.TableHeading>{"Checks erstellt "}</fk.TableHeading>
                                <fk.TableHeading>{"Davon MF"}</fk.TableHeading>
                                <fk.TableHeading>{"Davon FN"}</fk.TableHeading>
                                <fk.TableHeading>{"Checks beantwortet"}</fk.TableHeading>
                                <fk.TableHeading>{"Davon MF"}</fk.TableHeading>
                                <fk.TableHeading>{"Davon FN"}</fk.TableHeading>
                            </fk.TableRow>
                        </fk.TableHead>
                        <fk.TableBody>
                            {reportTableRows}
                            {reportTableSums}
                        </fk.TableBody>
                    </fk.Table>
                </fk.StackItem>
                    <fk.Stack
                        orientation="horizontal"
                        alongAxisRule="reversed"
                        expansion="alongAxis"
                    >
                        {paginationButtons}
                    </fk.Stack>
            </fk.Stack>
        </ReportTableWrapper>
    );
}
